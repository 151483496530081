const config = {
  MIXPANEL_TOKEN: '856a598269246057195f88c29a606d7b',
  JOBS_NEARBY_INTERVIEW: 'cf833137-bacd-42ca-9478-8c52697fbf78', // barista
  INSIGHT_PRO_INTERVIEW: '756e897b-bd92-40d6-b228-47c551ec3f0a',
  INTRODUCTION_INTERVIEW: 'dbeb914a-4cfb-4272-9f12-6ae6dff1c1d0',
  HOSPITALITY_INTERVIEW: '901993a0-82a7-42d9-b395-2d38c8755856',
  SENTRY_DSN: 'https://b686d7536053418ea10e710042e64aa3@o384120.ingest.sentry.io/5215220',
  PERSONALITY_TEST_FLOW_INTERVIEW: 'dbe6d117-7a46-4ea4-89bf-234c3591e4a7',
  API_ENDPOINT: 'https://api.interviewer.ai',
  AUTH0: {
    domain: 'candidates-prod.eu.auth0.com',
    clientID: 'sThrocJZqOcq62Lzl20hHX1WMKwL6XO6',
  },
  POST_INTERVIEW_REDIRECTS: {
    'Search4Maids API': 'https://search4maid.hk/interview/result',
  },
  WSG_ORGANIZATION_ID: ['91c01ecb-3b11-4b77-b42c-01c428af53bc', 'a843fd3a-1304-45aa-a2ea-c01eb5a8f78e'],
  DEFAULT_CANDIDATE_IMAGE: 'https://dev-interviewer-ai-assets.s3.amazonaws.com/Brand.jpg',
  INDIAN_PAYMENT_LINK_FOR_CANDIDATES: 'https://buy.stripe.com/dR69ExaUZcMsacM9AR',
  VIDEOS_BUCKET_URL: 'https://interviewer-ai-videos.s3.ap-southeast-1.amazonaws.com',
  US_PAYMENT_LINK_FOR_CANDIDATES: 'https://buy.stripe.com/8wMaIB8MR13KckUbIY',
  SLACK_NOTIFICATION: 'https://hooks.slack.com/services/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',
  STRIPE_PUBLISHABLE_KEY: 'pk_live_3CU6rb1tBPy1LPIxAKmHx5GJ',
  CANDIDATE_PLANS: {
    basic: {
      indianPlan: {
        amount: '999',
      },
      usPlan: {
        amount: '12',
      },
    },
  },
  // HIDING JOB DESCRIPTION AND JOBTYPE WITH THANK YOU PAGE SUPPORT EMAIL
  // including AIA's orgid
  HIDE_JOBTYPE_AND_SUPPORT_EMAIL: ['b272807f-79a4-4b22-8963-82bf2d1998b5'],
  HIDE_THANK_YOU_PAGE_INS_FOR_NUS: ['181c12f1-e0da-4e3d-a691-b003740e5cce'],
  // DISPLAYING COUNTDOWN TIMER FOR YESBANK ON RECORD AGAIN
  YESBANK_ORGANIZATION_ID: ['eba123b2-0d2f-4acb-b87d-5f74ddbf293b', '4838ec0d-d785-4250-a64d-bc9612a8c36f'],
  HIDE_RESPONSE_TIME_TEXT: ['cdaad4d3-eb3b-4375-b01d-7f8914ca3f32'], // emaar
  VIDEO_INTERVIEW_GATE_PROMPT_ORGS: ['eba123b2-0d2f-4acb-b87d-5f74ddbf293b', '26d9ac49-db17-4414-887c-84e3c039d7c3', '4409de1a-7246-4c44-8782-93a32301f4ae', '3e8fdf83-1c6d-4942-8b82-38a06743d504', '5d2b9f2c-27e8-4924-afaa-01f6c8da413c'],
  INTERVIEWERAI_DOMAIN: 'https://interviewer.ai/',
  CAREER_SIDE_INTERVIEWERAI_DOMAIN: 'https://careers.interviewer.ai/',
  INTERVIEWERAI_DASHBOARD_DOMAIN: 'https://dashboard.interviewer.ai/',
  INTERVIEWERAI_RESUME_BUILDER_DOMAIN: 'https://dashboard.interviewer.ai/',
  HIDE_ORGANIZATION_LOGO: ['d91c3deb-b9fc-4025-a543-fbe86988af1d', 'a6e9a828-edaf-4d54-85c0-357ecbeed44b'],
  ENTERPRISE_CLIENTS: ['be36a9f7-a0ef-46b3-9cea-a24325aeee8d', 'f354feef-a22f-4519-a867-4d157a915aed', 'a6e9a828-edaf-4d54-85c0-357ecbeed44b', '4409de1a-7246-4c44-8782-93a32301f4ae', '4be7427b-4428-435a-bf61-43b03934f0db'],
  AIA_API_ENDPOINT: 'https://dc2.aia-financial.co.id/app/gateway/iRecruite/3.0/recruit/v1',
  AIA_ORGANISATION_ID: '2d4bab6a-970a-4629-8b2b-7c206396e021',
  HIDE_RETAKE_AFTER_LIMIT: ['2d4bab6a-970a-4629-8b2b-7c206396e021', 'b272807f-79a4-4b22-8963-82bf2d1998b5', '4409de1a-7246-4c44-8782-93a32301f4ae'],
  AIA_API_KEY: '6cac878d-f7f8-4162-9431-42584f545034',
  HIDE_CANDIDATE_PORTAL_LINK: ['2d4bab6a-970a-4629-8b2b-7c206396e021', 'b272807f-79a4-4b22-8963-82bf2d1998b5', '4409de1a-7246-4c44-8782-93a32301f4ae'],
  DEMO_AVATAR_QUESTIONS: [
    'https://avatar-questions-dev.s3.ap-southeast-1.amazonaws.com/demo-question.mp4',
    'https://avatar-questions-dev.s3.ap-southeast-1.amazonaws.com/6fc397d3-30f9-4acb-b22b-0f0b665cbefc.mp4',
    'https://avatar-questions-dev.s3.ap-southeast-1.amazonaws.com/2e9b35b9-03c6-4705-9410-d567193be9f4.mp4',
    'https://avatar-questions-dev.s3.ap-southeast-1.amazonaws.com/9fa813e2-6650-4392-94dd-ad636720b604.mp4',
  ],
  AVATAR_QUESTION_ORG: [],
  AVATAR_QUESTION_INTERVIEW: 'db8009f7-eeb9-49e3-b5ec-936451c5f1ed',
  AVATAR_QUESTIONS: [
    'ae9d1f62-5ec9-4f15-a165-58ffe5b1339d',
    'aa562b62-75ff-41cb-96e5-6769692b0ed5',
    'ec120adc-c211-40ca-80d5-384ac722ab92',
  ],
  APPLICATION_ID: 'd64e8e65-ab19-488b-9949-5d59e4e92ce3',
  APPLICATION_REGION: 'us-east-1',
  guestRoleArn: 'arn:aws:iam::380359251827:role/RUM-Monitor-us-east-1-380359251827-7940689591071-Unauth',
  identityPoolId: 'us-east-1:a0f5d277-4d01-4c20-b0bd-ac22ba75fd73',
  CANDIDATE_EXTRA_INFORMATION: ['05c9428a-5ff6-4f0e-beac-987879aae905', '4409de1a-7246-4c44-8782-93a32301f4ae', 'caa487ad-1fd9-4198-bd67-adcbbe4bdc4d', '5c8b8993-fe55-4a4b-9e73-0ab71b4c9161', '4c6cbdd2-e515-41cd-a597-f24f6e06e66f', '91c01ecb-3b11-4b77-b42c-01c428af53bc', '24f997c9-5be1-4c8b-8bf5-bec90a3e9fd2', '725d45e7-d4e0-4db0-b9d2-5143a6b3fc94', '19a3ced9-329f-4522-9687-51886a0a1e55', 'fa32f7e9-f5d0-45a2-a245-28471e2ab042', '039897e0-61ab-4a18-9cca-eb7be6b0ea07', '19cf7ace-47c8-4d42-a7a2-874b96412945'],
  SOLVE_CUBE_UI: 'd91c3deb-b9fc-4025-a543-fbe86988af1d',
  ENVIRONMENT: 'Production',
  CAREER_GRIT_ORG_ID: 'c78c056b-5133-4df6-8abc-84f8926675a4',
  AIA_ORG_ID: ['b272807f-79a4-4b22-8963-82bf2d1998b5', '4c8b1710-4350-4eab-a85e-6ba714a76643'],
  APPLYBOARD_ORG_IDS: ['caa487ad-1fd9-4198-bd67-adcbbe4bdc4d'],
  REJECT_APPLY_OPTIONS: {
    '8a5fe76e-fe98-40a7-97e0-2c8437b65075': ['Less than 20'],
    '1b095dc3-f8ed-4c16-a7e3-fc84c19609f3': ['Others'],
    '144b45ab-c4f4-4c96-9db1-cdd97fa18018': ['Da Nang'],
    '15f2a463-b92f-42dd-990f-8ba67c545cc7': ['SMA'], // Education
    'd41b5506-fe32-4c1c-8f53-19b5bab3ecec': ['18', '19', '20', '21', '22', '45', '46', '47', '48', '49', '50', '51+'], // Age
    'c35baab9-8df5-418d-a592-dea32c824a4e': ['0-1 Tahun', '1-2 Tahun'], // Experience
  },
  NEW_RECORDING_SITE: 'https://v2.interviewer.ai',
};

export default config;
