export default {
  helloApplicant: 'Olá candidato,',
  thankYou: 'Obrigado.',
  question: 'Pergunta',
  start: 'Começar',
  submitting: 'Enviando',
  submit: 'Enviar',
  sending: 'Enviando',
  proceed: 'Prosseguir',
  close: 'Fechar',
  poweredBy: 'Distribuído por',
  contactUs: 'Contate-nos',
  showMore: 'Mostrar mais',
  showLess: 'Mostrar menos',
  applying: 'Candidatura',
  apply: 'Aplicar',
  click: 'Clique',
  done: 'Feito',
  uploading: 'Enviando',
  cantFindPage: 'Não encontramos a página que você procura.',
  usefulLinks: 'Aqui estão alguns links que podem ser úteis',
  menuHome: 'Lar',
  menuAboutUs: 'Sobre nós',
  menuTnC: 'Termos e Condições',
  menuPrivacy: 'Privacidade',
  tnc1: 'Ao marcar esta caixa, você concorda com ',
  tnc2: 'Termos de uso do candidato do Interviewer.AI',
  amInterested: 'estou interessado',
  second: 'segundo',
  second_plural: 'segundos',
  secondWithCount: '{{count}} segundo',
  secondWithCount_plural: '{{count}} segundos',
  minute: 'minuto',
  minute_plural: 'minutos',
  minuteWithCount: '{{count}} minuto',
  minuteWithCount_plural: '{{count}} minutos',
  workmapScreen: {
    greeting: 'Querido {{name}},',
    unansweredQuestions: 'Suas perguntas sem resposta são:',
    description:
      'Você está convidado a participar de uma avaliação de WorkMap (habilidade) para a função de {{jobName}} com {{companyName}}.',
    instruction: 'Instrução',
    instruction1:
      'Por favor, responda a cada afirmação com base em como você tem estado durante a maior parte do seu tempo de trabalho.',
    instruction2:
      'Ao responder às afirmações, selecione a opção que reflete como você geralmente pensa ou sente.',
    instruction3:
      'Não há respostas certas ou erradas nesta avaliação, por isso não perca muito tempo com nenhuma resposta.',
    instruction4:
      'Seja espontâneo e tente não responder com base no que você acha que queremos ouvir.',
    instruction5: 'Seja honesto em suas respostas. ',
    instruction6:
      'Esta avaliação deve levar aproximadamente 5 minutos para ser concluída. ',
    goBack: 'Volte',
    skillsAssessment: 'Avaliação de habilidades',
    of: 'de',
    answers: 'Respostas',
    questions: 'questões',
    submit: 'Enviar',
    start: 'Começar',
    instruction7: 'Você ainda tem perguntas sem resposta. ',
    stronglyDisagree: 'Discordo totalmente',
    disagree: 'Discordo',
    somewhatDisagree: 'Discordo um pouco',
    neitherAgreeNorDisagree: 'Nem concordo nem discordo',
    somewhatAgree: 'Concordo um pouco',
    agree: 'Concordar',
    stronglyAgree: 'Concordo plenamente',
    aiaInstruction1: '',
    aiaInstruction2: '',
    aiaInstruction3: '',
  },
  scale: {
    'Not at all': 'De jeito nenhum',
    Rarely: 'Raramente',
    Sometimes: 'Às vezes',
    Often: 'Muitas vezes',
    'Very Often': 'Muitas vezes',
    'Strongly Disagree': 'Discordo totalmente',
    'Somewhat Disagree': 'Discordo um pouco',
    Disagree: 'Discordo',
    'Neither Agree nor Disagree': 'Nem concordo nem discordo',
    Neutral: 'Neutro',
    Agree: 'Concordar',
    'Somewhat Agree': 'Concordo um pouco',
    'Strongly Agree': 'Concordo plenamente',
  },
  applySendSuccessful: {
    thankyou: 'Obrigado pelo seu envio!',
    instruction:
      'Enviamos um e-mail para você com o link para a página da entrevista. ',
    instruction1: '',
    instruction2: '',
    instruction3: '',
  },
  applySuccessful: {
    congrats: 'Ei, {{name}}!',
    submitted: 'Obrigado pelas informações fornecidas.',
    whatsNext: 'O que há de novo ?',
    instruction:
      'A próxima etapa do processo de inscrição é gravar as respostas em vídeo às perguntas da entrevista.',
    coachingInstruction:
      'Como parte da avaliação on-line, você deverá gravar uma(s) resposta(s) em vídeo para a(s) pergunta(s) de avaliação',
    instruction1:
      'Escolha um local tranquilo e garanta que o áudio e a iluminação estejam claros',
    instruction2: 'Verifique se há problemas de conectividade antes de começar',
    instruction3:
      'Seja confiante e alto o suficiente para que o áudio seja bem capturado',
    instruction4:
      'As entrevistas em vídeo devem ser concluídas dentro de um prazo razoável. ',
    instruction5: 'Aguardamos suas respostas em vídeo. ',
    giveBestShot:
      'Esta é a sua oportunidade de mostrar sua personalidade, paixão e energia, então dê o seu melhor!',
    helpfulTips: 'Dicas úteis:',
    doWithin48: 'Faça isso em 48 horas - envie o link para mim',
    wsgClientInstruction1:
      '5. Observe que o WSG examinará todos os vídeos gravados antes de enviá-los aos empregadores. ',
    doNow: 'Comece agora',
    infoProvide: 'Obrigado pelas informações fornecidas',
    opportunityText:
      'Esta é a sua oportunidade de mostrar sua personalidade, paixão e energia, então dê o seu melhor!',
    quietSpot: 'Encontre um bom espaço',
    strongNetwork: 'Rede forte',
    confidentLoud: 'Fale claramente',
    videoCompleted: 'Envios',
  },
  applySuccessfulDelayInterview: {
    thankyou: 'Obrigado {{name}}!',
    submitted: 'Sua inscrição foi enviada.',
    instruction:
      'Nossa equipe analisará sua inscrição e entrará em contato com você em breve se você estiver sendo selecionado para a próxima rodada. ',
    goodLuck: 'Desejo-lhe boa sorte!',
  },
  candidateProfile: {
    intro: 'Apresente-se',
    instruction:
      ' Antes de fazer a entrevista em vídeo, pedimos que você reserve um momento para se apresentar.',
    placeholder: 'Conte-nos sobre você',
  },
  browser: {
    worksBest: 'Interviewer.AI funciona melhor no Chrome e Firefox no momento.',
    bestExperience:
      'Estamos trabalhando para garantir que todos tenham a melhor experiência nas entrevistas!',
    meantime:
      'Enquanto isso, instale a versão mais recente do Google Chrome ou Mozilla Firefox e acesse o mesmo link da entrevista:',
    downloadChrome: 'Baixe o Chrome',
    downloadFirefox: 'Baixe o Firefox',
    lookForward: 'Aguardamos suas respostas!',
    bestRegards: 'Atenciosamente,',
    interviewerTeam: 'Entrevistador.Equipe AI',
    scanQrCode: 'Digitalize o código QR para gravar no celular',
  },
  digitalProfile: {
    interviews: 'Entrevistas',
    interviewName: 'Nome da entrevista',
    status: 'Status',
    dateResponded: 'Data da resposta',
    summary: 'Resumo',
    topSkills: 'Principais habilidades',
    viewResume: 'Ver currículo',
    visitLinkedin: 'Visite Linkedin',
    myWorkmapScore: 'Pontuação do meu mapa de trabalho',
  },
  organisationIntroVideoScreen: {
    continue: 'Continuar',
    note: '(Nota: o botão Continuar será ativado após assistir ao vídeo completo)',
  },
  endedScreen: {
    recordAgain: 'Grave novamente',
    recordAgainQuestion: 'Tem certeza de que deseja gravar novamente?',
    recordAgainDescription:
      'Observe que isso exigirá que você refaça toda a entrevista da pergunta 1. Pressione o botão Gravar novamente para reiniciar, ou você pode fechar esta janela e enviar suas respostas.',
    thankyou: 'Obrigado {{name}}! ',
    coachingThankyou: 'Obrigado {{name}}! ',
    instruction1: 'Você pode revisar suas respostas acima antes',
    instruction2: ' enviando-os com o botão na parte inferior da página.',
    getFeedback:
      'Para nos ajudar a melhorar nosso produto, agradecemos seus comentários abaixo! ',
    feedback: 'Opinião',
    placeholder: 'Seu feedback é muito apreciado!',
    rateExp: 'Avalie sua experiência de entrevista',
    Hi: 'Ei!',
    checkVideo: 'Vamos verificar seu vídeo.',
    question: 'Questões',
    submit: 'Enviar',
  },
  thankyouEndScreen: {
    radioText1: 'Não estou interessado no trabalho',
    radioText2: 'Não tenho interesse em entrevista em vídeo',
    radioText3: 'Farei isso mais tarde',
    radioText4: 'Não foi possível enviar',
    radioText5: 'Outros',
    pleaseChooseReason: 'Escolha um motivo',
    stateYourReason: 'Por favor, indique o seu motivo',
    notFinished: 'Você não terminou a entrevista.',
    notSubmittedVideoResp: 'Sua resposta ao vídeo não foi enviada.',
    noSubmittion: 'Sem submissão!',
    shareYourFeedback: 'Por favor, compartilhe seus comentários.',
    tellUsQuittingInterview:
      'Diga-nos se você está desistindo da entrevista ou enfrentando algum desafio.',
    ratingStarsMandatory: 'As estrelas de avaliação são obrigatórias',
    pleaseDontLeaveField: 'Por favor não deixe este campo vazio',
    congratulations: 'Parabéns',
    responseSent: 'Suas respostas em vídeo foram enviadas com sucesso.',
    instruction1:
      'Espere até receber uma mensagem de envio bem-sucedido, pois sua inscrição pode estar em processamento',
    instruction2: 'Fazemos o possível para avaliar sua resposta em minutos. ',
    instruction3: 'Você receberá um e-mail assim que for avaliado. ',
    instruction4:
      'Você pode entrar em contato conosco pelo e-mail support@interviewer.ai para qualquer dúvida adicional. ',
    finishedInterview: 'Você terminou a entrevista.',
    shareExperience: 'Compartilhe sua experiência de gravação.',
    submit: 'Enviar',
    thankyou: 'Obrigado',
    feedbackText:
      'Para nos ajudar a melhorar nosso produto, agradecemos seus comentários abaixo! ',
    feedback: 'Opinião',
  },
  interviewCompleted: {
    submitted: 'Você enviou uma resposta à entrevista.',
    thankyou: 'Obrigado pela sua resposta! ',
  },
  interviewFailure: {
    errorMessage:
      'Notamos que o link que você acessou é inválido ou não existe. ',
    title: 'Ops, o link da entrevista parece ser inválido!',
    contactUsBody:
      'Não foi possível acessar o link da entrevista em vídeo&body=Olá, não consegui acessar o link da entrevista: {{- link }}. ',
  },
  interviewSignUp: {
    name: 'Nome',
    apply: 'Inscreva-se agora',
    submit: 'Enviar',
    experience: 'Experiência',
    seniorityLevel: 'Nível de antiguidade',
    employmentType: 'Tipo de emprego',
    roleOverview: 'Visão geral',
    companyName: 'Empresa',
    namePlaceholder: 'Seu nome',
    applicationForm: 'Formulário de inscrição',
    required: 'Obrigatório',
    email: 'E-mail',
    emailPlaceholder: 'seu_e-mail@exemplo.com',
    school: 'Escola',
    phone: 'Telefone',
    linkedin: 'Perfil do LinkedIn',
    facebook: 'Perfil do Facebook',
    identityProof: 'Identificar Prova',
    identityTypes:
      '(Passaporte/carteira de motorista/identificação emitida pelo governo)',
    identityFormats: 'Formatos aceitos: jpg Tamanho máximo do arquivo: 2 MB',
    instagram: 'Perfil do Instagram',
    resume: 'Currículo (somente pdf, doc e docx)',
    errorExpired: 'Ops, o link da entrevista parece ter expirado!',
    errorMessage:
      'Notamos que o link que você acessou não está mais disponível. ',
    contactUsBody:
      'Não foi possível acessar o link da entrevista em vídeo&body=Olá, não consegui acessar o link da entrevista: {{- link }}. ',
    toApplyFor: 'Para se candidatar',
    coachingToApplyFor:
      'Para continuar com sua avaliação, envie os detalhes abaixo:',
    submitInfo: ' , envie suas informações abaixo:',
    selectFile: 'Selecione o arquivo',
    supportPDF: 'Suporta apenas PDF, doc e docx',
    dragPDF: 'Arraste seu arquivo aqui ou clique para',
    browse: 'navegar',
    your: 'Seu',
    prefilledNameNote:
      'Encontramos seu perfil associado a este e-mail, por isso o preenchemos previamente para você. ',
    IP: {
      programOfStudy: 'Programa de Estudo',
      school: 'Escola',
      formTitle:
        'Registro de entrevista de vídeo simulado on-line do InsightPro',
      instruction1:
        '1. Ao concluir o cadastro, você pode optar por iniciar a entrevista em vídeo imediatamente ou concluí-la posteriormente através do link fornecido por e-mail.',
      instruction2:
        '2. A entrevista inclui 8 questões comportamentais e terá duração aproximada de 15 minutos.',
      instruction3:
        '3. Seu Relatório de Avaliação da Entrevista será entregue no e-mail cadastrado em até 3 dias úteis após a conclusão da entrevista.',
      instruction4:
        '* Observe que entrevistas incompletas ou inválidas não serão revisadas e, portanto, nenhum resultado da avaliação estará disponível.',
      toApplyFor: 'Para se candidatar',
      at: 'no',
      howYouHear: 'Como você ficou sabendo desse evento?',
      indicateIndustry:
        '(Indique qual setor e cargo(s) você está interessado em seguir.)',
      yearOfGrad: 'Ano de formatura',
      careerInterests: 'Interesses de carreira',
      submitInfo: ' , envie suas informações abaixo:',
      linkedin: 'Mídias Sociais - LinkedIn',
      wechat: 'Mídias Sociais - WeChat',
      friend: 'Amigo',
      infoBooth: 'Balcões de informação',
      searchEngine: 'Mecanismo de pesquisa',
    },
    sopDocument: 'Documento de declaração pessoal (SOP)',
  },
  interviewSignUpSideScreen: {
    processHeadingWithWorkmapEnabled: 'Processo de 3 etapas',
    processHeadingWithoutWorkmapEnabled: 'Processo de 2 etapas',
    fillApplication: 'Preencha o formulário',
    completeAssessment: 'Avaliação completa',
    videoResponse: 'Gravar resposta de vídeo',
  },
  multiAttempts: {
    title: 'Ah, não, o link é válido apenas para acesso único!',
    errorMessage:
      'Notamos que o link que você acessou não está mais disponível. ',
    contactUsBody:
      'O link da entrevista é válido apenas para acesso único&body=Olá, não consegui acessar o link da entrevista: {{- link }}. ',
  },
  preQuestionReview: {
    title: 'Esta é uma gravação da pergunta do teste',
    pleaseNote: 'Observe:',
    description1:
      'Embora este vídeo não seja enviado, é assim que seu vídeo aparecerá ',
    description2: '. ',
    employer: 'empregador',
    warning:
      'NOTA: Depois de iniciar a entrevista, você não poderá voltar ou reiniciar.',
    coachingWarning:
      'NOTA: Depois de iniciar a avaliação, você não poderá voltar ou reiniciar.',
    instruction1: 'Ao iniciar a entrevista, você só tem',
    coachingInstruction1: 'Ao iniciar a avaliação, você só tem',
    instruction2: 'para ler a pergunta e se preparar para sua resposta. ',
    prepTime: '{{prepTime}} segundos ',
    troubleShootRedirection:
      'Link de suporte para ajudar e solucionar problemas de câmera/microfone ',
    clickLink: 'Clique aqui',
  },
  interview: {
    testAgain: 'Teste novamente',
    startInterview: 'Iniciar entrevista',
    recordResponse: 'Gravar resposta',
    goodLuck: 'Boa sorte!',
    interview: 'valor',
    '10seconds': ' Você tem 10 segundos para responder a esta pergunta.',
    allowDevices:
      'Você precisa permitir que o navegador acesse a câmera e o microfone.',
    done: 'Feito',
    preparation: 'Preparação',
    testQuestion: 'Esta é uma pergunta de teste',
    remainingTime: 'Tempo restante',
    back: 'Voltar',
    checkYourVideo: 'Verifique seu vídeo',
    okayLetsProceed: 'Ok, vamos prosseguir',
    startPractice: 'Comece a praticar',
    importantNotes: 'Notas importantes:',
    instruction1: 'Primeiro, teste com uma questão prática',
    instruction2: 'Não contará como uma submissão',
    instruction3: 'Clique em Iniciar teste para começar',
    startTest: 'Iniciar teste',
    alreadyApplied: 'Já aplicado!',
    alreadyAppliedToTheJob: 'Você já se candidatou a esta vaga usando',
    clickBelowToProceed: 'Clique abaixo para prosseguir.',
    continue: 'Continuar',
    blockerHeading:
      'Você precisa ativar o microfone e as permissões de vídeo para seguir em frente.',
    blockerPrimaryText:
      'Para ativar as permissões de microfone e vídeo, siga as etapas a seguir.',
    forChrome: 'Para Cromo:',
    chromeBlockerStep1: '1) No canto superior direito, clique em Mais. ',
    chromeBlockerStep2:
      '2) Clique em Privacidade e segurança Configurações do site. ',
    forFireFox: 'Para Firefox:',
    firefoxBlockerStep1:
      '1) Clique no botão de menu e selecione Configurações.',
    firefoxBlockerStep2:
      '2) Clique em Privacidade e segurança no menu esquerdo.',
    firefoxBlockerStep3: '3) Role para baixo até a seção Permissões.',
    firefoxBlockerStep4:
      '4) Clique no botão Configurações para a opção Microfone e vídeo',
    questions: 'Pergunta',
  },
  preQuestion: {
    qtitle: 'Diga-nos qual é a sua cor favorita e porquê?',
    title1: 'Este é um',
    title2: ' questão prática ',
    title3: 'para testar seu vídeo e áudio.',
    notSubmitted: 'Ele não será adicionado ao seu envio.',
    toTest: 'testar',
    instruction1:
      ' Quando estiver confortável com a experiência de gravação, clique em',
    instruction2: 'na parte inferior para prosseguir.',
    warning: 'NOTA: Você só tem 1 tentativa para esta entrevista. ',
    coachingWarning: 'NOTA: Você só tem 1 tentativa para esta avaliação real. ',
    '10seconds': ' Você tem 10 segundos para responder a esta pergunta.',
    allowDevices:
      'Você precisa permitir que o navegador acesse a câmera e o microfone.',
    numOfQuestions:
      'Há um total de {{numberOfQuestions}} perguntas nesta entrevista.',
    troubleShootRedirection:
      'Link de suporte para ajudar e solucionar problemas de câmera/microfone ',
    clickLink: 'Clique aqui',
  },
  recording: {
    howManySeconds:
      'Você tem {{seconds}} segundos para responder a esta pergunta.',
    recordingStatus: 'Gravação',
    preparationStatus: 'Preparação',
  },
  submitFailure: {
    ohNo: 'Oh não!',
    description: '',
    instruction1:
      'Infelizmente, não foi possível capturar suas respostas gravadas com sucesso.',
    instruction2:
      'Reserve um momento para regravar suas respostas a essas perguntas.',
    instruction3:
      'Se você continuar enfrentando desafios após reenviar, sinta-se à vontade para entrar em contato conosco pelo e-mail ',
    instruction4:
      'Você também pode regravar suas respostas a essas perguntas em outro dispositivo, digitalizando o qrcode abaixo.',
    recordAgain: 'Grave novamente',
    networkStatus: 'Status da rede',
    connected: ' Conectado',
    notConnected: ' Não conectado',
    check1: 'Verifique se você ',
    check2: 'tenha acesso à internet aqui.',
    reSubmit: 'Reenviar',
  },
  submittedScreen: {
    title: 'Sua resposta foi enviada!',
    thankyou:
      'Obrigado pelo seu precioso tempo para nos entrevistar, esperamos que tenha gostado da experiência!',
    redirect1: 'Agora você será redirecionado para {{time}} segundos...',
    orClick: 'Ou clique ',
    here: 'aqui',
    ifNotRedirected: ' se você não for redirecionado',
    redirectToPortal: 'para fazer login / inscrever-se no portal do candidato.',
    clickHere: 'Clique aqui',
  },
  submittingScreen: {
    title:
      'Estamos enviando suas respostas, por favor não feche seu navegador...',
  },
  landing: {
    dear: 'Querido {{name}},',
    hi: 'Oi {{name}},',
    previous: 'Anterior',
    next: 'Próximo',
    description:
      'Como parte da entrevista on-line, você deverá realizar uma entrevista em vídeo para a função de',
    coachingDescription:
      'Como parte da avaliação on-line, você deverá realizar uma entrevista em vídeo para a função de',
    with: 'com ',
    at: 'no',
    quietSpot:
      'Encontre um local tranquilo com ampla iluminação e WiFi forte para fazer sua avaliação.',
    instructions: 'Instruções',
    instruction1: 'Esta avaliação em vídeo consiste em',
    instruction1a: 'pergunta(s) e deve levar você',
    instruction1b:
      'para completar, dependendo do número de perguntas atribuídas a você.',
    instruction2: 'Você deve concluir sua avaliação em uma única sessão.',
    instruction3:
      'Assim que sua primeira pergunta for apresentada, um cronômetro automático será iniciado.  ',
    instruction3a:
      ', seguido de um limite de tempo de gravação que pode variar para cada questão.',
    instruction4:
      'Lembre-se de acompanhar o cronômetro enquanto prepara ou grava sua resposta. ',
    instruction5:
      'Observação: não atualize, feche ou saia da página após o início da entrevista, pois você não terá mais permissão para acessar o link da entrevista.',
    coachingInstruction5:
      'Observação: não atualize, feche ou saia da página após o início da avaliação, pois você não terá mais permissão para acessar o link da avaliação.',
    instruction6:
      'Não se preocupe, você poderá revisar suas respostas em vídeo ao final de toda a avaliação. ',
    instructionRedo:
      '* No entanto, a ordem e a natureza das perguntas estão sujeitas a alterações para garantir que você pareça natural e espontâneo durante a entrevista em vídeo.',
    envCheck: 'Verificação de Equipamento/Ambiente',
    env1: 'Encontre um local tranquilo com ampla iluminação e WiFi forte para fazer sua avaliação.',
    env2: 'A gravação da entrevista só funciona no Chrome e Firefox no momento. ',
    env3: 'Para prosseguir com a entrevista em vídeo, precisaremos de acesso à sua câmera de vídeo e ao seu microfone.',
    coachingEnv3:
      'Para prosseguir com a avaliação de vídeo, precisaremos de acesso à sua câmera de vídeo e ao seu microfone.',
    env4: 'Você verá uma pergunta prática onde poderá testar a qualidade de áudio e vídeo antes de iniciar a avaliação real.',
    env5: 'Certifique-se de se posicionar bem dentro da moldura da tela, sente-se ereto e olhe diretamente para a câmera ao apresentar suas respostas.',
    env6: 'Você poderá revisar suas respostas em vídeo no final de toda a avaliação. ',
    readyClick: 'Clique no botão “Continuar” quando estiver pronto. ',
    resumeOnly: 'Currículo (somente pdf, doc e docx) *',
    error: 'Por favor carregue apenas o arquivo pdf. ',
    env7: 'Não alterne ou alterne entre seus navegadores durante a gravação.',
    env8: 'Mantenha contato visual e não se distraia muito enquanto registra suas respostas.',
    env9: 'O áudio está sendo captado enquanto você grava. ',
    instruction7:
      'Faça upload dos documentos de identificação corretos conforme exigido (ID do governo, etc.)',
  },
  resumeParser: {
    title: 'Retomar demonstração do analisador',
    description:
      'Carregue um currículo abaixo para ver como nosso sistema analisa e avalia o currículo do candidato',
    dragResume:
      'Arraste e solte um currículo aqui ou clique para selecionar o arquivo (somente pdf)',
    parsedResults: 'Resultados analisados',
    experience: 'Experiência',
    personalDetails: 'Detalhes pessoais',
    totalYoE: 'Total de anos de experiência',
    company: 'Empresa',
    position: 'Posição',
    duration: 'Duração',
    skills: 'Habilidades',
    education: 'Educação',
    institute: 'Instituto',
    degree: 'Grau',
    major: 'Principal',
    score: 'Pontuação',
    overall: 'Geral',
    betterFitNote:
      'Observação: os candidatos com uma pontuação geral no currículo de 55 a 100 são mais adequados com base na descrição e nos requisitos do cargo atribuídos.',
    name: 'Nome',
    email: 'E-mail',
    phone: 'Telefone',
  },
  countdown: {
    recordIn: 'Gravar em',
  },
  prepTimer: {
    timer: 'Tempo de preparação',
  },
  candidateDetails: {
    back: 'Voltar',
    download: 'Download',
    questions: 'Questões',
    videoTranscript: 'Transcrição do vídeo',
    resumeScore: 'Retomar pontuação',
    workmapScore: 'Pontuação do mapa de trabalho',
    aiVideoScore: 'Pontuação de vídeo de IA',
    videoAssessment: 'Avaliação de entrevista em vídeo',
    text: 'Texto',
    voice: 'Voz',
    facialExpression: 'Expressão facial',
    professionalism: 'Profissionalismo',
    energyLevel: 'Níveis de energia',
    communication: 'Comunicação',
    sociability: 'Sociabilidade',
    pace: 'Ritmo',
    charity: 'Clareza',
    sentiments: 'Sentimento',
    energy: 'Energia',
    positivity: 'Positividade',
    positiveEmotion: 'Emoção Positiva',
    aboutThisAssessmentReport: 'Sobre este relatório de avaliação',
    introduction: 'Introdução',
    useOfReport: 'Uso do relatório',
    confidentialityDisclaimer:
      'Confidencialidade e isenção de responsabilidade',
    workmapAssessment: 'Avaliação do mapa de trabalho',
    actualScore: 'Pontuações reais',
    resumeScoring: 'Retomar pontuação',
    excellent: 'Excelente',
    high: 'Alto',
    medium: 'Médio',
    low: 'Baixo',
    glossaryIndex: 'Glossário/Índice',
    definitionOfWorkMapSkill: 'Definição de habilidade de mapa de trabalho',
    definitionOfVideoInterviewParameter:
      'Definição dos parâmetros da Entrevista em Vídeo',
    candidateSummary: 'Resumo do Candidato',
    notAvailable: 'Não disponível',
  },
  errors: {
    '404_CANDIDATE_DATA_NOT_FOUND':
      'Por favor, complete sua entrevista em vídeo para ver as avaliações.',
    '404_ASSESSMENT_DATA_NOT_READY':
      'Sua entrevista em vídeo está sendo avaliada. ',
    '404_CANDIDATE_NOT_AVAILABLE':
      'Você ainda não fez nenhuma entrevista em vídeo. ',
    default: 'Ocorreu um erro. ',
  },
  companyDetails: {
    company: 'Empresa',
    about: 'Sobre',
    experience: 'Experiência',
    apply: 'Aplicar',
    description: 'Descrição',
    viewJobDetailText: 'Ver detalhes do trabalho',
    readMore: 'Leia mais',
    readLess: 'Leia menos',
  },
  dashboardPanel: {
    dashboard: 'Painel',
    hey: 'Ei',
    morningGreeting: 'Bom dia',
    afternoonGreeting: 'Boa tarde',
    eveningGreeting: 'Boa noite',
    upgrade: 'Atualizar',
    support: 'Apoiar',
    signOut: 'Sair',
    collapse: 'Colapso',
    faqs: 'Perguntas frequentes',
    company: 'Empresa',
    role: 'Papel',
    status: 'Status',
    action: 'Ação',
    date: 'Data',
    recordNow: 'Grave agora',
    responded: 'Respondeu',
    processing: 'Processamento',
    viewDetails: 'Ver detalhes',
    personalInfo: 'Informações pessoais',
    introVideo: 'Vídeo de introdução',
    score: 'Pontuação',
    email: 'E-mail',
    phone: 'Telefone',
    summary: 'Resumo',
    topSkill: 'Principais habilidades',
    workmapScore: 'Pontuação do mapa de trabalho',
    aiVideoScore: 'Pontuação de vídeo de IA',
    introText1: 'Prepare-se para insights interessantes. ',
    introText2: 'seu vídeo de introdução.',
    recordVideo: 'Gravar vídeo',
    settings: 'Configurações',
    yourInterviews: 'Suas entrevistas',
  },
  candidatePortalSettings: {
    settings: 'Configurações',
    publicProfile: 'Perfil Público',
    profileVisibility: 'Seu perfil fica visível para todos.',
    closeAccount: ' Fechar conta',
    primaryConfirmationText: 'lamentamos ver você partir. ',
    secondaryConfirmationText:
      'Isso apagaria todos os seus dados e qualquer entrevista simulada à qual você possa ter respondido. ',
    reasoningHeading: ' Conte-nos por que você está encerrando sua conta:',
    primaryFeedbackText:
      'Valorizamos seus comentários, mas observe que não podemos responder aos comentários sobre sua conta enviados neste site. ',
    clickHere: 'Clique aqui',
    buttonSectionPrimarytext:
      'Por favor, confirme o encerramento da sua conta?',
    deleteButtonText: 'Sim, estou',
    cancelButtonText: 'Agora não',
    modalConfirmtext:
      'Esta ação levará à exclusão/retirada de qualquer inscrição que você tenha feito no passado usando nossa plataforma. ',
    confirm: ' Confirmar',
  },
  purchaseBanner: {
    heading:
      'Atualize para visualizar seu relatório detalhado de entrevista de IA',
    subHeading: 'Esteja confiante em sua próxima rodada de entrevistas.',
    launchAt: 'Oferta de lançamento em',
    only: 'Apenas',
    year: 'ano',
  },
  additionalFormField: {
    chooseFileBtn: 'Escolha o arquivo',
    pleaseChooseAFileText: 'Por favor escolha um arquivo',
  },
  domainQuestion: {
    domainAssessment: 'Avaliação de Domínio',
    of: 'de',
    submit: 'Enviar',
    questions: 'questões',
    answers: 'respostas',
    submittedAssessment: 'Você enviou a avaliação do domínio.',
    autoRedirect:
      ' Redirecionando automaticamente para a próxima etapa em 3 segundos...',
  },
  quitInterviewModal: {
    confirmQuit: 'Confirmar entrevista de encerramento',
    areYouSure: 'Tem certeza de que deseja sair da entrevista?',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
  },
  processingVideo: {
    instruction1: 'Processando seu vídeo...',
    instruction2: 'Por favor, aguarde',
    instruction3: 'Isso levará apenas alguns segundos...',
    instruction4: 'Ser paciente.',
    noAudioFound: 'Nenhum áudio encontrado, regrave com áudio nítido. ',
  },
  audioError: {
    message: 'Lamentamos que tenha ocorrido um erro ao transcrever seu vídeo. ',
  },
  reject: {
    thanksForInterest: 'Obrigado pelo seu interesse.',
    notQualified: 'Infelizmente, você não está qualificado para prosseguir com esta aplicação.',
    bestWishes: 'Desejamos a você tudo de melhor.',
  },
};
