export default {
  start: 'Comenzar',
  submitting: 'Enviando',
  submit: 'Enviar',
  sending: 'Enviando',
  proceed: 'Proceder',
  close: 'Cerrar',
  poweredBy: 'Desarrollado por',
  contactUs: 'Contáctanos',
  showMore: 'Mostrar más',
  showLess: 'Mostrar menos',
  applying: 'Aplicando',
  apply: 'Aplicar',
  click: 'Presionar',
  done: 'Hecho',
  uploading: 'Subiendo',
  cantFindPage: 'No podemos encontrar la página que estás buscando',
  usefulLinks: 'Aquí tienes algunos enlaces que pueden ser útiles',
  menuHome: 'Home',
  menuAboutUs: 'Sobre nosotros',
  menuTnC: 'Términos y condiciones',
  menuPrivacy: 'Privacidad',
  tnc1: 'Al aplicar, usted está de acuerdo con',
  tnc2: 'Interviewer.AI términos de uso del candidato',
  amInterested: 'Estoy interesado',
  workmapScreen: {
    greeting: 'Hola {{name}},',
    unansweredQuestions: 'Tus preguntas sin respuesta son:',
    description: 'Te invitamos a rendir la evaluación de competencias para el puesto de {{jobName}} en {{companyName}}.',
    instruction: 'Instrucciones',
    instruction1: 'Cuando usted brinda sus respuestas a la evaluación, por favor, tome como referencia cómo ha estado la mayor parte del tiempo en el entorno laboral.',
    instruction2: 'Por favor, lea las siguientes declaraciones y SELECCIONE la opción que mejor refleja como usted piensa o se siente.',
    instruction3: 'No hay respuestas correctas o incorrectas en esta evaluación. Por lo tanto, no pase mucho tiempo en cada declaración.',
    instruction4: 'Sea espontáneo en sus respuestas, y trate de no pensar basado en “excepciones a la regla”.',
    instruction5: 'Sea honesto. Sus respuestas afectará la precisión de su evaluación y puede ser evaluada más adelante con una entrevista en video una vez que ha sido preseleccionado.',
    instruction6: 'Esta evaluación debería tomar aproximadamente 5 minutos para ser completada. Haz clic en el botón “Comenzar” cuando estés listo(a). ¡Te deseamos lo mejor!',
    instruction7: 'Todavía tienes preguntas sin respuesta. Por favor complete todas las preguntas antes de enviar.',
    stronglyDisagree: 'Muy en desacuerdo',
    disagree: 'Discrepar',
    somewhatDisagree: 'algo en desacuerdo',
    neitherAgreeNorDisagree: 'Ni de acuerdo ni en desacuerdo',
    somewhatAgree: 'Parcialmente de acuerdo',
    agree: 'Aceptar',
    stronglyAgree: 'Totalmente de acuerdo',
  },
  scale: {
    'Not at all': 'En absoluto',
    Rarely: 'Raramente',
    Sometimes: 'A veces',
    Often: 'A menudo',
    'Very Often': 'Muy a menudo',
    'Strongly Disagree': 'Completamente en desacuerdo',
    Disagree: 'En desacuerdo',
    Neutral: 'Neutral',
    Agree: 'De acuerdo',
    'Strongly Agree': 'Completamente de acuerdo',
  },
  applySendSuccessful: {
    thankyou: '¡Gracias por aplicar!',
    instruction: 'Te hemos enviado un correo electrónico con un enlace para la página de la entrevista. Recomendamos grabar tu video dentro de las siguientes 48 horas para que tengas más probabilidades de ser invitado(a) a la siguiente etapa. ¡Mucha suerte!',
  },
  applySuccessful: {
    congrats: '¡Felicidades {{name}}!',
    submitted: 'Tus respuestas han sido enviadas.',
    whatsNext: '¿Qué sigue?',
    instruction: 'El siguiente paso en tu aplicación para el puesto de {{jobName}} es realizar una entrevista en video.',
    coachingInstruction: 'El siguiente paso en su proceso de solicitud es grabar una respuesta en video a la pregunta de evaluación.',
    instruction1: '1. Escoge un lugar tranquilo y asegúrate de que esté iluminado.',
    instruction2: '2. Asegúrate de mantener una conexión de internet estable.',
    instruction3: '3. Muéstrate seguro y mantén tu voz lo suficientemente fuerte para que podamos escucharte claramente.',
    instruction4: '4. Los videos de tus respuestas pueden ser completados en cualquier momento antes de la fecha de expiración.',
    instruction5: 'Esperamos ansiosos tus videos respuestas. ¡Mucha suerte!',
    giveBestShot: 'Esta es tu oportunidad para demostrar tu personalidad, pasión, y energía, ¡así que hazlo lo mejor que puedas!',
    helpfulTips: 'Algunos consejos',
    doWithin48: 'Lo haré dentro de 48 horas - envíame el link',
    doNow: 'Lo haré ahora',
    wsgClientInstruction1:
      '5. Qǐng zhùyì,WSG jiàng zài tíjiāo gěi gùzhǔ zhīqián shāixuǎn suǒyǒu lùzhì de shìpín. Bāohán huò xiǎnshì bù shìdàng huò bù zhuānyè xíngwéi (lìrú shǐyòng gōngjí xìng cíyǔ, bù shìdàng de bèijǐng) de shìpín jiāng bù huì tíjiāo gěi gùzhǔ yǐ chǔlǐ gōngzuò shēnqǐng. Jiāng tōngzhī qiúzhí zhě xiūgǎi qí lùzhì de shìpín bìng chóngxīn tíjiāo.',
    infoProvide: 'Gracias por la información proporcionada.',
    opportunityText: 'Esta es tu oportunidad de mostrar tu personalidad, pasión y energía, ¡así que haz tu mejor esfuerzo!',
    quietSpot: 'Lugar tranquilo y asegurar',
    strongNetwork: 'Red fuerte',
    confidentLoud: 'Confiado y lo suficientemente ruidoso',
    videoCompleted: 'Los vídeos se pueden completar.',
  },
  applySuccessfulDelayInterview: {
    thankyou: '¡Gracias {{name}}!',
    submitted: 'Tu aplicación ha sido enviada.',
    instruction: 'Nuestro equipo revisará tu aplicación y te contactará lo más pronto para avisarte si calificas a la siguiente etapa.',
    goodLuck: 'Te deseamos buena suerte.',
  },
  candidateProfile: {
    intro: 'Preséntate a ti mismo(a).',
    instruction: 'Antes de comenzar con la entrevista en video, por favor, escribe una descripción para presentarte a ti mismo(a).',
    placeholder: 'Cuéntanos sobre ti.',
  },
  browser: {
    worksBest: 'Interviewer.AI funciona mejor en Chrome y Firefox, actualmente.',
    bestExperience: '¡Estamos trabajando para asegurar que todos tengan la mejor experiencia durante entrevistas de trabajo!',
    meantime: 'Por mientras, instala la versión más reciente de Google Chrome o Mozilla Firefox y accede al mismo enlace de entrevista:',
    downloadChrome: 'Descarga Chrome',
    downloadFirefox: 'Descarga Firefox',
    lookForward: '¡Estamos esperando tu respuesta!',
    bestRegards: 'Saludos cordiales,',
    interviewerTeam: 'Equipo de Interviewer.AI',
    scanQrCode: 'Escanea el código QR para grabar en el móvil',
  },
  digitalProfile: {
    interviews: 'Interviews',
    interviewName: 'Interview Name',
    status: 'Status',
    dateResponded: 'Date Responded',
    summary: 'Summary',
    topSkills: 'Top Skills',
    viewResume: 'View Resume',
    visitLinkedin: 'Visit Linkedin',
    myWorkmapScore: 'My Workmap Score',
  },
  organisationIntroVideoScreen: {
    continue: 'continuar',
    note: '(Nota: el botón Continuar se habilitará después de ver el video completo)',
  },
  endedScreen: {
    recordAgain: 'Grabar de nuevo',
    recordAgainQuestion: '¿Estás seguro de que quieres grabar de nuevo?',
    recordAgainDescription: 'Tome en cuenta que esta acción requerirá que retomes toda la entrevista desde la primera pregunta. Presiona el botón de grabar de nuevo para intentarlo nuevamente, o puedes cerrar esta ventana y proceder enviando tus respuestas actuales.',
    thankyou: 'Gracias {{name}}! Has terminado la entrevista.',
    coachingTankyou: '¡Gracias {{name}}! Has terminado la evaluación.',
    instruction1: 'Usted puede revisar sus respuestas por adelantado',
    instruction2: 'enviándolos usando el botón al final de la página.',
    getFeedback: 'Para ayudarnos a mejorar nuestro producto, ¡apreciaríamos sus comentarios abajo! ¿Qué piensa usted de la experiencia de entrevista?',
    feedback: 'Comentarios/Opinión',
    placeholder: '¡Su opinión es muy apreciada!',
    rateExp: 'Evalúa tu experiencia durante la entrevista',
  },
  interviewCompleted: {
    submitted: 'Usted envió sus respuestas de entrevista.',
    thankyou: '¡Gracias por tu respuesta! Si tiene alguna pregunta, por favor, siéntase libre de contactarnos :)',
  },
  interviewFailure: {
    title: 'Ups, el enlace de la entrevista parece no ser válido.',
    linkExpired: 'El enlace puede haber expirado o la entrevista finalizó de forma temprana.',
    getInTouch: 'Por favor, contáctenos, así podemos confirmar el estado de su entrevista.',
  },
  interviewSignUp: {
    name: 'Nombre',
    namePlaceholder: 'Tu nombre',
    email: 'Correo electrónico',
    emailPlaceholder: 'tu_correoelectrónico@ejemplo.com',
    school: 'Institución',
    phone: 'Celular',
    linkedin: 'Perfil de LinkedIn',
    resume: 'CV (solo pdf)',
    errorExpired: 'Ups, ¡el enlace de la entrevista ha expirado!',
    errorClosed: 'La entrevista puede haber sido cerrada por el empleador. Si no esperaba que esto sucediera, por favor, comuníquese con nosotros para que podamos confirmar el estado de la conversación.',
    IP: {
      programOfStudy: 'Programa de estudios',
      school: 'Institución',
      formTitle: 'Registro de entrevista de video online simulado',
      instruction1: '1. Al completar el registro, puede elegir entre comenzar la entrevista en video inmediatamente, o completarla más tarde a través del enlace proporcionado por correo electrónico.',
      instruction2: '2. La entrevista incluye 8 preguntas de comportamiento y tendrá una duración aproximada de 15 minutos.',
      instruction3: '3. Su informe de evaluación de la entrevista se enviará al correo electrónico registrado dentro de los 3 días hábiles posteriores a la finalización de la entrevista.',
      instruction4: '* Tenga en cuenta que las entrevistas incompletas o inválidas no serán revisadas y, por lo tanto, no estarán disponibles los resultados de la evaluación.',
      toApplyFor: 'Aplicar para',
      coachingToApplyFor: 'Para continuar con su evaluación, envíe los detalles a continuación:',
      companyClient: ' cliente de {{companyName}}',
      at: 'en',
      howYouHear: '¿Cómo te enteraste acerca de este evento?',
      indicateIndustry: '(Indique en qué industria y puesto(s) está interesado en ejercer)',
      yearOfGrad: 'Año de graduación',
      careerInterests: 'Intereses de carrera',
      submitInfo: ' , envíe su información a continuación:',
      linkedin: 'Red social - LinkedIn',
      wechat: 'Red social - WeChat',
      friend: 'Amigo',
      infoBooth: 'Cabinas de información',
      searchEngine: 'Buscador',
    },
  },
  interviewSignUpSideScreen: {
    processHeading: 'Proceso de 3 pasos',
    fillApplication: 'Llenar solicitud',
    completeAssessment: 'Evaluación completa',
    videoResponse: 'Grabar respuesta en video',
  },
  multiAttempts: {
    title: '¡Oh no, el enlace es válido solamente para un único acceso!',
    description: 'Dado que comenzó la entrevista antes, ya no tiene acceso al enlace.',
    getInTouch: 'Por favor, comuníquese con nosotros para que podamos confirmar el estado final de la conversación.',
  },
  preQuestionReview: {
    title: 'Esta es una grabación de prueba',
    pleaseNote: 'Tenga en cuenta:',
    description1: 'Si bien este video no se enviará, así es como aparecerá su video para ',
    description2: '. Asegúrese de que su rostro sea visible frente a la cámara y que su voz sea clara. ',
    employer: 'empleador',
    warning: 'NOTA: Una vez que la entrevista haya comenzado, no podrá volver atrás ni reiniciar.',
    coachingWarning: 'NOTA: Una vez que comience la evaluación, no podrá volver atrás ni reiniciar.',
    instruction1: 'Cuando comience la entrevista, lo que tiene que hacer es',
    coachingInstruction1: 'Cuando comienza la evaluación, solo tiene,',
    instruction2: 'leer la pregunta y prepararse para la respuesta. ¡Buena suerte!',
    prepTime: '{{prepTime}} segundos',
    troubleShootRedirection: 'Enlace de soporte para ayudar y solucionar problemas de cámara/micrófono',
    clickLink: 'haga clic aquí',
  },
  interview: {
    testAgain: 'Por favor, prueba de nuevo',
    startInterview: 'Comenzar entrevista',
    recordResponse: 'Grabar respuesta',
    testQuestion: 'Esta es una pregunta de prueba',
    goodLuck: '¡Buena suerte!',
    interview: 'Valor',
    '10 segundos': 'Tiene 10 segundos para responder esta pregunta.',
    allowDevices: 'Usted necesita permitir a su navegador el acceso a la cámara y micrófono.',
    done: 'Hecho',
    preparation: 'Preparación',
    remainingTime: 'Tiempo restante',
    back: 'atrás',
    checkYourVideo: 'revisa tu video',
    okayLetsProceed: 'Bien, procedamos',
    startPractice: 'Iniciar práctica',
    importantNotes: 'Notas importantes:',
    instruction1: 'Primero, prueba con una pregunta de práctica.',
    instruction2: 'No contará como envío',
    instruction3: 'Haga clic en Iniciar prueba para comenzar',
    startTest: 'Iniciar prueba',
    alreadyApplied: '¡Ya aplicado!',
    alreadyAppliedToTheJob: 'Ya has aplicado a este trabajo usando',
    clickBelowToProceed: 'Haga clic a continuación para continuar.',
    continue: 'Continuar',
    blockerHeading: 'Debe activar su micrófono y permisos de video para seguir adelante.',
    blockerPrimaryText: 'Para activar los permisos de micrófono y video, siga los siguientes pasos.',
    forChrome: 'Para cromo:',
    chromeBlockerStep1: '1) En la parte superior derecha, haz clic en Más. Ajustes.',
    chromeBlockerStep2: '2) Haga clic en Privacidad y seguridad Configuración del sitio. Cámara o Micrófono.',
    forFireFox: 'Para Firefox:',
    firefoxBlockerStep1: '1) Haga clic en el botón de menú y seleccione Configuración.',
    firefoxBlockerStep2: '2) Haz clic en Privacidad y seguridad en el menú de la izquierda.',
    firefoxBlockerStep3: '3) Desplácese hacia abajo hasta la sección Permisos.',
    firefoxBlockerStep4: '4) Haga clic en el botón Configuración para la opción Micrófono y video',
  },
  preQuestion: {
    qtitle: 'Cuéntanos tu color favorito y por qué?',
    title1: 'Esta es',
    title2: 'una pregunta de práctica ',
    title3: 'para probar su video y audio.',
    notSubmitted: 'No será añadido a tu postulación.',
    toTest: 'Probar',
    instruction1: 'Una vez que se sienta cómodo grabando, haga clic en',
    instruction2: 'el botón para continuar.',
    warning: 'NOTA: Solo tiene 1 intento en esta entrevista. Utilice esta pregunta para probar su voz y video antes de comenzar la entrevista real.',
    '10 segundos': 'Usted tiene 10 segundos para responder esta pregunta.',
    allowDevices: 'Usted necesita permitir a su navegador el acceso a la cámara y micrófono.',
    numOfQuestions: 'Hay un total de {{numberOfQuestions}} preguntas en esta entrevista.',
    troubleShootRedirection: 'Enlace de soporte para ayudar y solucionar problemas de cámara/micrófono',
    clickLink: 'haga clic aquí',
  },
  recording: {
    howManySeconds: ' Usted tiene {{seconds}} segundos para responder esta pregunta',
    recordingStatus: 'Grabando',
    preparationStatus: 'Preparación',
  },
  submitFailure: {
    ohNo: '¡Oh no!',
    description: 'Encontramos un error al enviar su respuesta. Normalmente, esto se debe a conexiones de red intermitentes que provocan la caída de la transmisión. Lamentamos los inconvenientes ocasionados.',
    instruction1: 'Lamentablemente, no pudimos capturar correctamente sus respuestas grabadas.',
    instruction2: 'Tómese un momento para volver a grabar sus respuestas a estas preguntas.',
    instruction3: 'Si continúa enfrentando desafíos después de volver a enviar, no dude en comunicarse con nosotros en ',
    instruction4: 'También puede volver a grabar sus respuestas a estas preguntas en otro dispositivo escaneando el siguiente código qr.',
    recordAgain: 'Grabar de nuevo',
    networkStatus: 'Estado de conexión',
    connected: 'Conectado',
    notConnected: 'No conectado',
    check1: 'Verifique si usted',
    check2: 'tiene acceso de internet aquí.',
    reSubmit: 'Por favor, reenvíe',
  },
  submittedScreen: {
    title: '¡Tu respuesta ha sido enviada!',
    thankyou: 'Gracias por su valioso tiempo hablando con nosotros. ¡Esperamos que haya disfrutado de la experiencia!',
    redirect1: 'Será redirigido en {{time}} segundos...',
    orClick: 'o haga clic ',
    here: 'aquí',
    ifNotRedirected: 'si usted no es redirigido',
  },
  submittingScreen: {
    title: 'Estamos enviando tu aplicación, no cierres el navegador...',
  },
  landing: {
    dear: 'Estimado(a) {{name}},',
    hi: 'Hola {{name}},',
    previous: 'Previo',
    next: 'Siguiente',
    description: 'Como parte de la evaluación de la entrevista en línea, debe completar una entrevista de video',
    coachingDescription: 'Como parte de la evaluación de la entrevista en línea, debe completar una entrevista en video para el puesto de',
    with: 'con',
    at: 'en',
    companyClient: ' el cliente de {{company}}',
    quietSpot: 'Encuentre un lugar tranquilo con mucha iluminación y Wi-Fi estable para hacer su evaluación.',
    instructions: 'Instrucciones',
    instruction1: 'Esta evaluación en video consiste en ',
    instruction1a: 'una pregunta(s) y debería llevarlo a ',
    instruction1b: 'completar dependiendo de la cantidad de preguntas asignadas.',
    instruction2: 'Debe completar la evaluación en una sola sesión.',
    instruction3: 'Un temporizador automático comenzará cuando se muestra la primera pregunta. Se le dará un tiempo de preparación',
    instruction3a: ', seguido de un límite de tiempo de grabación que puede diferir para cada pregunta.',
    instruction4: 'Recuerde hacer un seguimiento del cronómetro mientras prepara o graba su respuesta. Si se encuentra listo antes del límite de tiempo, puede optar por comenzar a registrar sus respuestas o enviarlas de antemano.',
    instruction5: 'Nota: No actualice, cierre ni salga de la página después de que haya comenzado la entrevista, ya que no podrá acceder al enlace de la entrevista.',
    coachingInstruction5: 'Please Note: Do not refresh, close or exit the page once the assessment has started, as you would not be allowed to access the assessment link anymore.',
    instruction6: 'No se preocupe, podrá revisar sus respuestas en video al final de la revisión completa. Si cree que sus respuestas no son satisfactorias, puede repetir la evaluación completa',
    instructionRedo: '* Sin embargo, el orden y la naturaleza de las preguntas están sujetos a cambios para garantizar que usted se vea natural y espontáneo durante la video entrevista.',
    envCheck: 'Control de equipo / ambiente',
    env1: 'Encuentre un lugar tranquilo con mucha iluminación y Wi-Fi estable para hacer su evaluación.',
    env2: 'La grabación de Interviewer.AI solo funciona en Chrome y Firefox en este momento. Asegúrese de utilizar uno de estos navegadores para realizar la evaluación.',
    env3: 'Para continuar con la video entrevista, necesitaremos acceso a su cámara de video y micrófono.',
    coachingEnv3: 'Para continuar con la evaluación en video, necesitaremos acceso a su cámara de video y su micrófono.',
    env4: 'Se mostrará una pregunta de prueba en la que puede probar la calidad del audio y el video antes de comenzar la evaluación real.',
    env5: 'Asegúrese de ubicarse bien en el marco de la pantalla, siéntese derecho y mire directamente a la cámara mientras presenta sus respuestas.',
    env6: 'Al final de toda la evaluación, tendrá la oportunidad de revisar sus respuestas en video. Si cree que sus respuestas no son satisfactorias, puede repetir la evaluación completa.',
    readyClick: 'Haga clic en el botón "Continuar" cuando esté listo. ¡Buena suerte!',
    resumeOnly: 'CV (solo pdf) *',
    error: 'Cargue únicamente el archivo pdf., por favor. Archivos rechazados.',
    env7: 'No alterne ni cambie entre navegadores mientras graba.',
    env8: 'Mantenga el contacto visual y no se distraiga demasiado mientras registra sus respuestas.',
    env9: 'El audio se capta mientras grabas. Asegúrese de ser la única persona que habla durante la grabación de la entrevista.',
    instruction7: 'Cargue los documentos de identificación correctos según lo dispuesto (identificación gubernamental, etc.)',
  },
  resumeParser: {
    title: 'Reanudar la demostración del analizador',
    description: 'Sube un currículum a continuación para ver cómo nuestro sistema analiza y evalúa el currículum del candidato.',
    dragResume: 'Arrastre y suelte un currículum aquí, o haga clic para seleccionar el archivo (solo pdf)',
    parsedResults: 'Resultados analizados',
    experience: 'Experiencia',
    personalDetails: 'Detalles personales',
    totalYoE: 'Años totales de experiencia',
    company: 'Compañía',
    position: 'Puesto',
    duration: 'Duración',
    skills: 'Habilidades',
    education: 'Educación',
    institute: 'Instituto',
    degree: 'Grado',
    major: 'Especialización',
    score: 'Puntaje',
    overall: 'General',
    betterFitNote: 'Nota: Los candidatos con un puntaje general de currículum vitae de 55-100 encajan mejor en función de la descripción del trabajo y los requisitos asignados.',
    name: 'Nombre',
    email: 'Correo electrónico',
    phone: 'Celular',
  },
  countdown: {
    recordIn: 'Grabar en',
  },
  prepTimer: {
    timer: 'Tiempo de preparación',
  },
  candidateDetails: {
    back: 'Atrás',
    download: 'Descargar',
    questions: 'Preguntas',
    videoTranscript: 'Transcripción del vídeo',
    resumeScore: 'Puntuación de currículum',
    workmapScore: 'Puntuación del mapa de trabajo',
    aiVideoScore: 'Puntaje de video de IA',
    videoAssessment: 'Evaluación de la entrevista en video',
    text: 'Texto',
    voice: 'Voz',
    facialExpression: 'Expresión facial',
    professionalism: 'Profesionalismo',
    energyLevel: 'Niveles de energía',
    communication: 'Comunicación',
    sociability: 'Sociabilidad',
    pace: 'Ritmo',
    charity: 'Claridad',
    sentiments: 'Sentimiento',
    energy: 'Energía',
    positivity: 'Positividad',
    positiveEmotion: 'Emoción positiva',
    aboutThisAssessmentReport: 'Acerca de este informe de evaluación',
    introduction: 'Introducción',
    useOfReport: 'Uso del Informe',
    confidentialityDisclaimer: 'Confidencialidad y descargo de responsabilidad',
    workmapAssessment: 'Evaluación del mapa de trabajo',
    actualScore: 'Puntuaciones Reales',
    resumeScoring: 'Reanudar puntuación',
    excellent: 'Excelente',
    high: 'Alto',
    medium: 'Medio',
    low: 'Bajo',
    glossaryIndex: 'Glosario/Índice',
    definitionOfVideoInterviewParameter: 'Definición de parámetros de Video Entrevista',
    candidateSummary: 'Resumen del candidato',
    notAvailable: 'No disponible',

  },
  errors: {
    '404_CANDIDATE_DATA_NOT_FOUND': 'Complete su entrevista en video para ver las evaluaciones.',
    '404_ASSESSMENT_DATA_NOT_READY': 'Actualmente se está evaluando su video entrevista. ¡Vuelve pronto!',
    '404_CANDIDATE_NOT_AVAILABLE': 'Aún no ha realizado ninguna entrevista en vídeo. Vuelva después de enviar su respuesta a la entrevista en video.',
    default: 'Ha ocurrido un error. Por favor, inténtelo de nuevo más tarde.',
  },
  dashboardPanel: {
    dashboard: 'Tablero',
    hey: 'Oye',
    morningGreeting: 'Buenos Días',
    afternoonGreeting: 'buenas Tardes',
    eveningGreeting: 'buenas noches',
    upgrade: 'Mejora',
    support: 'Apoyo',
    signOut: 'Desconectar',
    collapse: 'Colapsar',
    faqs: 'FAQs',
    company: 'Empresa',
    role: 'Role',
    status: 'Estado',
    action: 'Acción',
    date: 'fecha',
    recordNow: 'Grabar ahora',
    responded: 'Respondido',
    processing: 'Procesando',
    viewDetails: 'Ver detalles',
    personalInfo: 'Información personal',
    introVideo: 'Vídeo de introducción',
    score: 'Puntaje',
    email: 'Correo electrónico',
    phone: 'Teléfono',
    summary: 'Resumen',
    topSkill: 'habilidad superior',
    workmapScore: 'Workmap Score',
    aiVideoScore: 'AI partitura de vídeo',
    introText1: 'Prepárese para obtener información emocionante. Empezar con la grabación',
    introText2: 'tu vídeo de introducción.',
    recordVideo: 'Grabar video',
  },
  purchaseBanner: {
    heading: 'Actualice para ver su informe detallado de la entrevista de IA',
    subHeading: 'Tenga confianza en su próxima ronda de entrevistas.',
    launchAt: 'Oferta de lanzamiento en',
    only: 'Solamente',
    year: 'año',
  },
  companyDetails: {
    company: 'Compañía',
    about: 'Sobre',
    experience: 'Experiencia',
    apply: 'Aplicar',
    description: 'Descripción',
    viewJobDetailText: 'Ver detalles del trabajo',
    readMore: 'Leer más',
    readLess: 'Leer menos',
  },
  additionalFormField: {
    chooseFileBtn: 'Elija el archivo',
    pleaseChooseAFileText: 'Elija un archivo',
  },
  processingVideo: {
    instruction1: 'Procesando tu video...',
    instruction2: 'Espere por favor',
    instruction3: 'Sólo te llevará unos segundos...',
    instruction4: 'Ser paciente.',
    noAudioFound: 'No se encontró audio. Vuelva a grabar con audio claro. Por favor verifique la conexión de su micrófono.',
  },
  audioError: {
    message: 'Lamentamos que haya habido un error al transcribir tu vídeo. Por favor revisa tu audio y vuelve a intentarlo.',
  },
  reject: {
    thanksForInterest: 'Gracias por tu interés.',
    notQualified: 'Desafortunadamente, no calificas para avanzar en esta aplicación.',
    bestWishes: 'Te deseamos lo mejor.',
  },
};
